import styled from '@emotion/styled';
import { H2 as BaseH2, H3 as BaseH3 } from '../styled/headings';

export const SmallText = styled.div`
  font-size: ${props => props.theme.fonts.smallSize};
`;

export const Cards = styled.ul`
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-left: 0;

  .card {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: ${({ theme }) => theme.spaces.md};
    padding: ${props => props.theme.spaces.md};
    background: #fff;
    box-shadow: ${props => props.theme.shadows.boxShadow};
    border-radius: ${props => props.theme.borders.borderRadius};
    @media (min-width: 660px) {
      flex-basis: 49%;
    }

    h3 {
      margin-top: 0;
    }

    a {
      margin-bottom: ${({ theme, withoutImage }) =>
        withoutImage ? 0 : theme.spaces.sm};
    }
  }
`;

export const H2 = styled(BaseH2)`
  text-align: center;
`;

export const H3 = styled(BaseH3)`
  margin-bottom: 0;
  font-weight: normal;
  font-size: 1.3rem;
  @media (min-width: 768px) {
    font-size: ${props => props.theme.fonts.s4};
  }
`;
