import React from 'react';
import Helmet from 'react-helmet';
import { ExternalLink } from '../components/shared/styled/a';
import { PageTitle } from '../components/shared/styled/headings';
import { H2, H3 } from '../components/shared/styled/region';
import { Content } from '../components/shared/styled/layout';
import Layout from '../components/Layout';

const styleTitle = { textAlign: 'left', marginTop: 0, marginBottom: 0 };

const SecondPage = () => (
  <Layout>
    <Helmet>
      <meta name="robots" content="noindex, nofollow" />
    </Helmet>

    <Content style={{ paddingTop: '3rem' }}>
      <PageTitle>Mentions légales et crédits</PageTitle>

      <H2 style={styleTitle}>Mentions légales</H2>

      <H3 style={styleTitle}>Coordonnées de l’hébergeur</H3>
      <p style={{ marginTop: 0 }}>
        Netlify, Inc., 2325 3rd Street, Suite 215
        <br />
        San Francisco, California 94107
        <br />
        support@netlify.com
      </p>

      <p>Touts droits réservés à Béatrice et Gilbert BERTRAND.</p>

      <H2 style={styleTitle}>Crédits photo</H2>

      <H3 style={styleTitle}>Page Notre région</H3>
      <ul>
        <li>
          <ExternalLink
            text="Château Lagarde, par Arno Lagrange"
            href="https://commons.wikimedia.org/wiki/File:Chateau_Lagarde_0.jpg"
          />
        </li>
        <li>
          <ExternalLink
            text="Château de Miglos, par Philippe Contal"
            href="https://commons.wikimedia.org/wiki/File:Le_château_de_Miglos_sous_la_pleine_lune.tif"
          />
        </li>
        <li>
          <ExternalLink
            text="Rivière souterraine de Labouiche, par Tylwyth Eldar"
            href="https://commons.wikimedia.org/wiki/File:Rivière_souterraine_de_Labouiche_07.jpg?uselang=fr"
          />
        </li>
        <li>
          <ExternalLink
            text="Grotte de Lombrives, par Oscar"
            href="https://commons.wikimedia.org/wiki/File:LombrivesEntree.jpg"
          />
        </li>
        <li>
          <ExternalLink
            text="Grotte de Niaux, par HTO"
            href="https://commons.wikimedia.org/wiki/File:Niaux,_bisons.JPG"
          />
        </li>
        <li>
          <ExternalLink
            text="Bonascre, par Antoine Blondin"
            href="https://commons.wikimedia.org/wiki/File:Pisteax.jpg"
          />
        </li>
        <li>
          <ExternalLink
            text="Plateau de Beille enneigé, par Maeva Loillier et Florent Paitrault"
            href="https://commons.wikimedia.org/wiki/File:Plateau_de_Beille.JPG"
          />
        </li>
        <li>
          <ExternalLink
            text="Etang d'Appy, par Pierre Benard"
            href="https://commons.wikimedia.org/wiki/File:EtangAppy.jpg"
          />
        </li>
        <li>
          <ExternalLink
            text="Eglise Notre-Dame-des-Anges de Collioure, par Fildefer"
            href="https://commons.wikimedia.org/wiki/File:%C3%89glise_Notre-Dame-des-Anges_de_Collioure_6.jpg"
          />
        </li>
        <li>
          <ExternalLink
            text="Fontaine intermittente de Fontestorbes, par Audouy"
            href="https://commons.wikimedia.org/wiki/File:Fontaine_de_Fontestorbes.JPG"
          />
        </li>
        <li>
          <ExternalLink
            text="Pic Saint-Barthélémy, par Pierre Benard"
            href="https://commons.wikimedia.org/wiki/File:StBarth220.jpg"
          />
        </li>
        <li>
          <ExternalLink
            text="Mont Fourcat, par Lucas Destrem"
            href="https://commons.wikimedia.org/wiki/File:Station_des_Monts_d%27Olmes_(12).jpg"
          />
        </li>
      </ul>
    </Content>
  </Layout>
);

export default SecondPage;
